<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col>
            <h1>Judging</h1>
          </b-col>
        </b-row>

        <b-row v-if="$apollo.loading">
          <b-col class="d-flex align-items-center justify-content-center">
            <h1>
              <i class="fas fa-spinner fa-spin ml-1"></i>
            </h1>
          </b-col>
        </b-row>

        <b-row v-else>
          <b-col>
            <b-row
              v-for="(score, index) in scores"
              :key="score.id"
              :class="index < scores.length - 1 ? 'mb-4' : ''"
            >
              <b-col>
                <h2 v-if="score.criteria">
                  {{ score.criteria.name }}
                </h2>
                <p v-if="score.criteria">
                  {{ score.criteria.description }}
                </p>
                <div v-if="score.criteria && score.criteria.type === 'SCORE'">
                  <base-slider
                    v-if="!score.completed"
                    :step="1"
                    :range="{
                      min: 0,
                      max: score.criteria.maxScore,
                    }"
                    v-model="score.score"
                  ></base-slider>
                  {{ Number(score.score) }}/{{ score.criteria.maxScore }}
                  <div v-if="score.completed">
                    <small class="text-muted"
                      >This score has been finalised</small
                    >
                  </div>
                </div>
                <div
                  v-else-if="score.criteria && score.criteria.type === 'BINARY'"
                >
                  <base-range-switch
                    v-if="!score.completed"
                    :max_value="score.criteria.maxScore"
                    v-model="score.score"
                  >
                  </base-range-switch>
                  {{ score.score }}/{{ score.criteria.maxScore }}
                  <div v-if="score.completed">
                    <small class="text-muted"
                      >This score has been finalised</small
                    >
                  </div>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col></b-col>
              <b-col sm="auto" class="text-right">
                <base-button
                  v-if="!all_scores_has_been_marked_complete"
                  type="success"
                  :pill="true"
                  class="my-4 btn-right"
                  :loading="complete_button.loading"
                  :success="complete_button.success"
                  :disabled="save_button.loading || complete_button.loading"
                  @click="save_and_complete_scores()"
                >
                  Finalise Score
                </base-button>
              </b-col>
              <b-col sm="auto" class="text-right">
                <el-tooltip
                  content="Please click the 'Finalise Score' button once you want to finalise your judging."
                  placement="top"
                >
                  <base-button
                    v-if="!all_scores_has_been_marked_complete"
                    type="primary"
                    :pill="true"
                    class="my-4 btn-primary btn-right"
                    :loading="save_button.loading"
                    :success="save_button.success"
                    :disabled="save_button.loading || complete_button.loading"
                    @click="save_scores()"
                  >
                    Save
                  </base-button>
                </el-tooltip>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
// Modules

import { processTeamRelayData } from "@/ORM/Hackathon/Team.js";
import {
  processHackathonRelayData,
  Hackathon,
} from "@/ORM/Hackathon/Hackathon.js";

import { processJudgingRelayData } from "@/ORM/Hackathon/Judging.js";

// Queries
import { GET_HACKATHON_SUBMISSION_SCORES } from "@/graphql/queries";

// Mutations
import { BATCH_UPDATE_HACKATHON_JUDGING_SCORE } from "@/graphql/mutations";

import BaseSlider from "@/components/BaseSlider";
import BaseRangeSwitch from "@/components/BaseRangeSwitch";

export default {
  name: "HackathonJudgingWidget",
  components: { BaseSlider, BaseRangeSwitch },
  props: {
    hackathon_id: {
      type: String,
      description: "The Id of the hackathon being worked on.",
      default: null,
    },
    submission_id: {
      type: String,
      description: "The Id of the submission being judged.",
      default: null,
    },
  },
  apollo: {
    get_hackathon_submission_scores: {
      query: GET_HACKATHON_SUBMISSION_SCORES,
      result(res) {
        console.log(res);

        this.scores = processJudgingRelayData(res) || [];
        this.all_scores_has_been_marked_complete = true;
        for (let i = 0; i < this.scores.length; i++) {
          if (!this.scores[i].completed) {
            this.all_scores_has_been_marked_complete = false;
            break;
          }
        }
      },
      error(errors) {
        console.log("Smart Query Error Handler: " + this.$options.name); // Check out https://stackoverflow.com/questions/66782888/how-do-i-consume-errors-in-my-vue-graphql-component-and-let-other-errors-be-hand
        console.log(errors.graphQLErrors);
        return false;
      },
      variables() {
        return {
          submission_id: this.submission_id,
        };
      },
      update(data) {
        this.apollo_data.get_hackathon_submission_scores = data;
      },
      skip: true,
    },
  },
  data() {
    return {
      apollo_data: {
        get_hackathon_submission_scores: null,
      },
      scores: [],
      score_values: [],
      save_button: {
        loading: false,
        success: false,
      },
      complete_button: {
        loading: false,
        success: false,
      },

      all_scores_has_been_marked_complete: false,
    };
  },
  methods: {
    manage_get_hackathon_submission_scores() {
      if (this.hackathon_id && this.submission_id) {
        graph_utils.set_fetch_network_only(
          this.$apollo.queries.get_hackathon_submission_scores
        );
        graph_utils.enable_query(
          this.$apollo.queries.get_hackathon_submission_scores
        );
      } else {
        graph_utils.disable_query(
          this.$apollo.queries.get_hackathon_submission_scores
        );
      }
    },

    save_scores() {
      this.save_button.loading = true;
      this.update_scores(this.get_update_variables())
        .then((res) => {
          this.save_button.loading = false;
          if ("data" in res) {
            this.save_button.success = true;
            setTimeout(() => {
              this.save_button.success = false;
            }, 2000);
          }
        })
        .catch((res) => {
          this.save_button.loading = false;
          console.log(res);

          this.$notify({
            message: "Could not save your scores, please try again.",
            timeout: 5000,
            icon: "ni ni-cross-bold",
            type: "danger",
          });
        });
    },

    save_and_complete_scores() {
      this.save_button.loading = true;
      this.update_scores(this.get_update_variables(true))
        .then((res) => {
          this.save_button.loading = false;
          if ("data" in res) {
            this.save_button.success = true;
            setTimeout(() => {
              this.save_button.success = false;
            }, 2000);
          }
        })
        .catch((res) => {
          this.save_button.loading = false;
          console.log(res);

          this.$notify({
            message: "Could not save your scores, please try again.",
            timeout: 5000,
            icon: "ni ni-cross-bold",
            type: "danger",
          });
        });
    },

    get_update_variables(mark_as_complete) {
      let scores_data = [];
      if (!this.scores.length > 0) {
        return scores_data;
      }
      this.scores.forEach((el) => {
        console.log(el);
        let data = {
          id: el.id,
          score: Number(el.score),
        };
        if (mark_as_complete) {
          data["completed"] = true;
        }
        scores_data.push(data);
      });
      return scores_data;
    },

    update_scores(scores_data) {
      return this.$apollo
        .mutate({
          mutation: BATCH_UPDATE_HACKATHON_JUDGING_SCORE,
          variables: {
            scores: scores_data,
          },
        })
        .then((res) => {
          if ("data" in res) {
            this.$notify({
              message: "Successfully saved your scores.",
              timeout: 3000,
              icon: "ni ni-check-bold",
              type: "success",
            });

            setTimeout(() => {}, 3000);
          } else {
            this.$notify({
              message: "Could not save your scores, please try again.",
              timeout: 5000,
              icon: "ni ni-cross-bold",
              type: "danger",
            });
          }
          return res;
        });
    },
  },
  watch: {
    hackathon_id() {
      this.manage_get_hackathon_submission_scores();
    },
    submission_id() {
      this.manage_get_hackathon_submission_scores();
    },
  },
  mounted() {
    if (!this.$apollo.queries.get_hackathon_submission_scores.skip) {
      this.$apollo.queries.get_hackathon_submission_scores.refetch();
    }
  },
  created() {},
};
</script>

<style></style>
